import React from "react"
import Highlight, { defaultProps } from "prism-react-renderer"
import theme from "prism-react-renderer/themes/vsDark"
import Prism from "prism-react-renderer/prism"
;(typeof global !== "undefined" ? global : window).Prism = Prism

require("prismjs/components/prism-python")
require("prismjs/components/prism-liquid")
require("prismjs/components/prism-scss")

function ExampleCode(props) {
  const className = props.children.props.className // the default is like className ="language-html" you need to remove lanugage-
  const language = className.replace(/language-/, "")
  console.log(props)

  return (
    <Highlight
      Prism={Prism}
      {...defaultProps}
      code={props.children.props.children.trim()}
      language={language}
      theme={theme}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => {
        return (
          <article className="example-code">
            <pre className={className} style={style}>
              <div className="code-tab">{language}</div>
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                  {line.map((token, key) => (
                    <span {...getTokenProps({ token, key })} />
                  ))}
                </div>
              ))}
            </pre>
          </article>
        )
      }}
    </Highlight>
  )
}

export default ExampleCode
