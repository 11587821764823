exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-consulting-consulting-js": () => import("./../../../src/pages/consulting/consulting.js" /* webpackChunkName: "component---src-pages-consulting-consulting-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-it-support-js": () => import("./../../../src/pages/IT/IT-support.js" /* webpackChunkName: "component---src-pages-it-it-support-js" */),
  "component---src-pages-onlinestore-about-shopify-js": () => import("./../../../src/pages/onlinestore/about-shopify.js" /* webpackChunkName: "component---src-pages-onlinestore-about-shopify-js" */),
  "component---src-pages-onlinestore-building-shopify-js": () => import("./../../../src/pages/onlinestore/building-shopify.js" /* webpackChunkName: "component---src-pages-onlinestore-building-shopify-js" */),
  "component---src-pages-start-up-startup-support-js": () => import("./../../../src/pages/start-up/startup-support.js" /* webpackChunkName: "component---src-pages-start-up-startup-support-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-support-management-support-js": () => import("./../../../src/pages/support/management-support.js" /* webpackChunkName: "component---src-pages-support-management-support-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-webdev-building-website-js": () => import("./../../../src/pages/webdev/building-website.js" /* webpackChunkName: "component---src-pages-webdev-building-website-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/category-template.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

