import React from "react"
import { Link } from "gatsby"
import { RiGatsbyFill } from "react-icons/ri"
import servicesList from "../constants/service-list"
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-center">
        <div className="footer-card">
          <h4>サービス</h4>
          {servicesList.map((service, index) => {
            return (
              <Link to={service.url} key={index}>
                {service.title}
              </Link>
            )
          })}
        </div>

        <div className="footer-card">
          <h4>YMworks</h4>
          <Link to="/">home</Link>
          <Link to="/contact">お問合せ</Link>
          <Link to="/blog">ブログ</Link>
        </div>
      </div>
      <div>
        <p>
          &copy; {new Date().getFullYear()} YMWorks built with Gatsby{" "}
          <RiGatsbyFill className="svg" /> *Shopifyは*Shopify.Incの商標です。
        </p>
      </div>
    </footer>
  )
}

export default Footer
