import React, { useState } from "react"
import { Link } from "gatsby"
import { FaBars } from "react-icons/fa"
const Navbar = () => {
  const [show, setshow] = useState(false)
  const toggleHandler = () => {
    setshow(!show)
  }
  const showOffhandler = () => {
    setshow(false)
  }
  return (
    <nav className="navbar">
      <div className="nav-center">
        <div className="nav-header">
          <Link className="nav-logo" to="/">
            YMWORKS
          </Link>
          <button
            type="button"
            className="btn-invert nav-btn "
            onClick={toggleHandler}
          >
            <FaBars />
          </button>
        </div>

        <div className={show ? "nav-links show-links" : "nav-links"}>
          <Link
            to="/onlinestore/building-shopify"
            className="nav-link"
            activeClassName="nav-active"
            onClick={showOffhandler}
          >
            オンラインストア構築
          </Link>
          <Link
            to="/webdev/building-website"
            className="nav-link"
            activeClassName="nav-active"
            onClick={showOffhandler}
          >
            サイト構築
          </Link>
          <Link
            to="/support/management-support"
            className="nav-link"
            activeClassName="nav-active"
            onClick={showOffhandler}
          >
            運営支援
          </Link>
          <Link
            to="/blog"
            className="nav-link"
            activeClassName="nav-active"
            onClick={showOffhandler}
          >
            Blog
          </Link>
          <div className="contact-link">
            <Link to="/contact" className="btn-invert" onClick={showOffhandler}>
              お問合せ
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
