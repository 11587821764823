import React from "react"
import { FiShoppingCart, FiSmartphone } from "react-icons/fi"
import { MdOutlineWeb, MdOutlineBusinessCenter } from "react-icons/md"
import { BiConversation, BiSupport } from "react-icons/bi"
const servicesList = [
  {
    icon: <FiShoppingCart />,
    url: "/onlinestore/building-shopify",
    title: "Shopify構築",
    description:
      "Shopifyパートナーがお客様のオンラインストア構築をサポートいたします。\n お客様のブランドイメージに応じたデザインのストアフロントをカスタマイズいたします。Liquidのカスタマイズ。",
  },
  {
    icon: <MdOutlineWeb />,
    url: "/webdev/building-website",
    title: "ウェブサイト構築",
    description:
      "最新トレンドに対応する、速く・見やすいウェブサイト制作を行います。スマートフォンなどモバイルサイズに対応したサイト構築。",
  },
  {
    icon: <BiSupport />,
    url: "/support/management-support",
    title: "運営支援",
    description:
      "Shopifyーウェブサイトの運営などのサポートサービスを行います。スタッフへのコーチングなど。\n Shopify運営のコーチングから管理・保守をサポートいたします。",
  },
  {
    icon: <FiSmartphone />,
    url: "/IT/IT-support",
    title: "IT活用支援",
    description: "IT活用をサポートいたします。",
  },
  {
    icon: <MdOutlineBusinessCenter />,
    url: "/start-up/startup-support",
    title: "創業支援",
    description:
      "これからビジネスをお考えの方へ、創業計画の立て方・スタートアップ等サポート支援いたします。",
  },
  {
    icon: <BiConversation />,
    url: "/consulting/consulting",
    title: "コンサルティング",
    description:
      "米国MBA経営修士ホルダーによる、分析・経営戦略立案のお手伝い。",
  },
]

export default servicesList
