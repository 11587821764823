import React from "react"
import { MDXProvider } from "@mdx-js/react"
import ExampleCode from "./src/components/utilities/Example-Code"

const components = {
  pre: ExampleCode,
}

export const wrapMDX = ({ element }) => {
  return <MDXProvider components={components}>{element}</MDXProvider>
}
